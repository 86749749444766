import "../styles/header.css"

const Header = () => {
    return (
        <header className="header">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <h1 style={{ color: "black" }}>The Anime</h1>
                <h1 style={{ color: "#1071e5" }}>Hub</h1>
            </div>
            <p>Encuentra las mejores series, OVAs y peliculas de anime</p>
        </header>
    );
};

export default Header;