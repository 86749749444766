import './App.css';
import Header from './components/Header';
import Progress from './components/Progress';
import 'bootstrap/dist/css/bootstrap.css';

//color: #1071E5;

function App() {
  return (
    <div className="App">
      <Header />
      <Progress/>
    </div>
  );
}

export default App;
