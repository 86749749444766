import ProgressBar from "react-bootstrap/ProgressBar";
import "../styles/progressbar.css"

function Progress() {
    const frbar = window.APP_CONFIG.frbar;
    const babar = window.APP_CONFIG.babar;
    return (
    <div style={{ width: "75%", margin: "2% auto 2% auto" }}>
      <div style={{ margin: "0 0 4% 0" }}>
        <h3>Frontend</h3>
        <ProgressBar striped className="cus-color-1" animated label={`${frbar}%`} now={frbar}/>
      </div>
      <div style={{ margin: "0 0 4% 0" }}>
        <h3>Backend</h3>
        <ProgressBar striped className="cus-color-2" animated label={`${babar}%`} now={babar}/>
      </div>
    </div>
  );
}

export default Progress;
